import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { siteMetadata } from '../../site-config'
import defaultShareIcon from '../../../static/images/social-share.png';

export const SEOComponent = ({pageTitle, meta, metaTitle, metaDescription, ogType, shareImage}) => {
  let links = [];

  return (
    <Helmet
        htmlAttributes={{
            lang: "en",
        }}
      title={pageTitle || siteMetadata.title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      link={links}
      meta={[
        {
          charSet: "utf-8"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          name: "title",
          content: metaTitle || pageTitle,
        },
        {
          name: "description",
          content: metaDescription || siteMetadata.description,
        },
        {
          property: "og:title",
          content: metaTitle || pageTitle,
        },
        {
          property: "og:description",
          content: metaDescription || siteMetadata.description,
        },
        {
          property: "og:type",
          content: ogType,
        },
        {
          property: "og:image",
          content: shareImage || `${siteMetadata.siteUrl}${defaultShareIcon}`,
        },
        {
          property: "twitter:title",
          content: metaTitle || pageTitle,
        },
        {
          property: "twitter:description",
          content: metaDescription || siteMetadata.description,
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:site",
          content: siteMetadata.twitterHandle,
        },
        {
          property: "twitter:image",
          content: shareImage || `${siteMetadata.siteUrl}${defaultShareIcon}`,
        }
      ].concat(meta)
    }
    >
      <script async src="/js/modernizr.js" />
    </Helmet>
  )
}

SEOComponent.defaultProps = {
  pageTitle: "",
  meta: [],
  metaTitle: "",
  metaDescription: "",
  ogType: "website",
  shareImage: ""
}

SEOComponent.propTypes = {
  pageTitle: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  shareImage: PropTypes.string,
  ogType: PropTypes.string
}

export default SEOComponent
